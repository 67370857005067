<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="border" />
			<div class="row">
				<div class="columns column2">
					<p>{{ $t('footerAddress') }}</p>
				</div>
				<div class="columns column1">
					<p>-></p>
				</div>
				<div class="columns column9">
					<p class="address">
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }} -
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}
						{{ defaults[locale].website.country }}
					</p>
				</div>
			</div>
			<div class="border" />
			<div class="row">
				<div class="columns column2">
					<p>{{ $t('footerCall') }}</p>
				</div>
				<div class="columns column1">
					<p>-></p>
				</div>
				<div class="columns column9">
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
					</p>
				</div>
			</div>
			<div class="border" />
			<div class="row">
				<div class="columns column2">
					<p>{{ $t('footerMail') }}</p>
				</div>
				<div class="columns column1">
					<p>-></p>
				</div>
				<div class="columns column9">
					<p>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
				</div>
			</div>
			<div class="border" />
			<div class="row">
				<div class="columns column2">
					<p>{{ $t('footerOpen') }}</p>
				</div>
				<div class="columns column1">
					<p>-></p>
				</div>
				<div class="columns column9">
					<div v-parse-links v-html="defaults[locale].website.openingHoursFooter" />
				</div>
			</div>
			<div class="border" />
			<div class="row footer-menu">
				<div class="columns column6 align-left">
					<social-media :socials="socials" />
				</div>
				<div class="columns column6 align-right">
					<span> | </span>
					<div v-for="item in footermenu[locale]" :key="item.filename">
						<nuxt-link :to="item.filename">
							<span>{{ item.header }}</span>
						</nuxt-link>
						<span> | </span>
					</div>
				</div>
			</div>
			<div class="border" />
			<div class="row">
				<div class="columns column12 align-right">
					<div class="footer-logo" />
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.footers {
	color: $footer-color;
	background: $footer-background-color;
}

.main-footer {
	.row {
		flex-flow: row nowrap;
	}

	.footer-logo {
		width: 90px;
		height: 29px;
		background: url('~/assets/images/logo-footer-ami-handwritten.png') no-repeat center center;
		background-size: 90px;
		margin: 25px 0;
		display: inline-block;
	}

	p {
		font-size: 0.9em;
	}

	a:hover {
		text-decoration: underline;
	}
}

.footer-menu {
	padding: 18px 0;

	div {
		display: inline-block;
	}
}

@media (max-width: 1200px) {
	.main-footer {
		padding-bottom: 100px;
	}
}

@media (max-width: 1080px) {
	footer {
		.column1 {
			max-width: 50px;
		}

		.column2 {
			max-width: 90px;
		}
	}
}
</style>
